<script setup lang="ts">
import { Card } from '#components'
import { breakpointsTailwind } from '@vueuse/core'
import { reactiveTransform } from '@vueuse/motion'
import type { PublicationSummary } from '~/components/card/index.vue'

const props = defineProps<
	{ publication: PublicationSummary } & {
		id: string
		index: number
		stackSize: number
	}
>()

const randomRotation = () => -4 + Math.random() * 8
const randomPosition = () => ({
	x: -20 + Math.random() * 40,
	y: 15 + Math.random() * -30,
})

const target = ref<HTMLElement>()
const initialRotation = randomRotation()
const position = randomPosition()

const isOnTop = computed(() => props.index === props.stackSize - 1)
const { isMobile: isMobileDevice } = useDevice()
const isMobileSize = useMediaQuery(`(max-width: ${breakpointsTailwind.sm}px`)
const isMobile = computed(() => isMobileDevice || isMobileSize.value)

const emit = defineEmits<{
	(e: 'move-up'): void
	(e: 'move-down'): void
	(e: 'move-start'): void
	(e: 'move-end'): void
	(e: 'swipe-right'): void
	(e: 'swipe-left'): void
}>()

const move = async (dir: 'up' | 'down') => {
	const targetRect = target.value?.getBoundingClientRect()
	if (!targetRect) return

	emit('move-start')

	spring = useSpring(state, {
		damping: 35,
		stiffness: 300,
	})

	const mult = dir === 'up' ? -1 : 1

	await spring.set({
		x:
			mult * 0.9 * targetRect.width +
			Math.random() * 0.25 * targetRect.width,
		y: 0.1 * targetRect.height - Math.random() * 0.1 * targetRect.height,
		rotateZ: spring.values.rotateZ + 25 * mult,
	})

	if (dir === 'up') emit('move-up')
	if (dir === 'down') emit('move-down')

	await spring.set({
		...randomPosition(),
		rotateZ: randomRotation(),
	})

	spring = useSpring(state, {
		damping: 50,
		stiffness: 400,
	})

	emit('move-end')
}

defineExpose({
	id: props.id,
	move,
})

const { state, transform } = reactiveTransform({
	x: 0,
	y: 0,
	rotateZ: 0,
	scale: 1,
})
state.x = 0
state.y = 0
state.rotateZ = 0
state.scale = 1
let spring = useSpring(state, {
	damping: 50,
	stiffness: 400,
})

const onPointerEnter = (e: PointerEvent) => {
	if (e.pointerType === 'touch' || !isOnTop.value) return
	e.preventDefault()
	e.stopPropagation()

	spring.set({
		rotateZ: cardRef.value?.flipped
			? state.rotateZ
			: parseFloat(state.rotateZ!.toString()) + -5 + Math.random() * 10,
		scale: 1.03,
	})
}

const onPointerLeave = (e: PointerEvent) => {
	e.preventDefault()
	e.stopPropagation()
	if (e.pointerType === 'touch' || !isOnTop.value) return
	spring.set({
		rotateZ: initialRotation,
		scale: 1.0,
	})
}

let dX = ref(0)

const el = ref<HTMLElement | null>(null)

onMounted(() => {
	el.value = document.documentElement
})

watch(
	() => props.index,
	() => {
		dX.value = 0
	},
)

const visible = useElementVisibility(target)
watch(visible, (isVisible) => {
	if (isVisible) {
		setTimeout(() => {
			spring.stop()
			spring.set({
				...position,
				rotateZ: initialRotation,
			})
		}, 200)
	} else {
		spring.stop()
		spring.set({
			x: 0,
			y: 0,
			rotateZ: 0,
		})
	}
})

const cardRef = ref<InstanceType<typeof Card>>()
const canClickCard = ref(isOnTop.value)
watch(isOnTop, () => {
	cardRef.value?.showCardFront()
	canClickCard.value = isOnTop.value
})

const onClick = () => {
	if (canClickCard.value) {
		if (!cardRef.value?.flipped) cardRef.value?.showCardBack()
	}
}
</script>

<template>
	<div
		class="card"
		:class="{
			['pointer-events-none']: !isOnTop,
		}"
		:style="{ transform, zIndex: index }"
		ref="target"
		@pointerenter="onPointerEnter"
		@pointerleave="onPointerLeave"
		@click="onClick"
		:aria-hidden="!isOnTop"
	>
		<Card
			ref="cardRef"
			:disable-focus="!isOnTop"
			:publication="publication"
			:disable-hover="true"
			:disable-click="!isMobile && canClickCard && !cardRef?.flipped"
			:prevent-rotate-on-click="!isMobile && !cardRef?.flipped"
		/>
	</div>
</template>

<style scoped>
.card {
	@apply absolute w-full h-full cursor-pointer;
}
</style>
