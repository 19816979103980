<script lang="ts" setup>
import type { PublicationSummary } from '~/components/card/index.vue'

const props = withDefaults(
	defineProps<{
		mode?: 'dark' | 'light'
		publications: PublicationSummary[]
	}>(),
	{
		mode: 'dark',
	},
)

const cards = ref()
const state = reactive({
	currentIndex: 0,
})
export type StackContext = typeof state

const nextCard = () => {
	if (cards.value.isMoving) return
	cards.value.next()
}

const prevCard = () => {
	if (cards.value.isMoving) return
	cards.value.prev()
}
</script>

<template>
	<div class="featured-section__cards text-left">
		<CollectionFeaturedStackCards
			ref="cards"
			:publications="publications"
			:state="state"
		/>

		<div class="featured-section__cards__pagination">
			<MiscCarouselPagination
				:mode="mode"
				:current-index="state.currentIndex"
				:amount="publications.length"
				@pressed-next="nextCard"
				@pressed-prev="prevCard"
			/>
		</div>
	</div>
</template>

<style scoped lang="postcss">
.featured-section__cards {
	@apply h-[520px] md:h-[620px] w-full md:h-full md:w-1/3 relative;
}

.featured-section__cards__pagination {
	@apply absolute top-1/2 left-1/2 -translate-x-1/2 translate-y-[200px] md:translate-y-[220px];
}
</style>
