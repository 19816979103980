<script setup lang="ts">
import type { PublicationQuery } from '~/generated/operations'
import type { StackContext } from './index.vue'

type Must<T> = {
	[P in keyof T]-?: NonNullable<T[P]>
}
type Item = Must<NonNullable<NonNullable<PublicationQuery['related']>[0]>>

const props = defineProps<{
	publications: Item[]
	state: StackContext
}>()

const items = ref()
const cards = ref(
	props.publications.map((p, idx) => ({
		...p,
		index: props.publications.length - 1 - idx,
	})),
)
const isMoving = ref(false)

const showPrevCard = () => {
	if (isMoving.value) return

	const cardOnBottom = [...cards.value]
		.sort((a, b) => b.index - a.index)
		.pop()
	const componentOnBottom = items.value.find(
		(i: any) => i.id === cardOnBottom?.id,
	)
	if (componentOnBottom) componentOnBottom.move('up')
}

const showNextCard = () => {
	if (isMoving.value) return

	const cardOnTop = [...cards.value].sort((a, b) => a.index - b.index).pop()
	const componentOnTop = items.value.find((i: any) => i.id === cardOnTop?.id)
	if (componentOnTop) componentOnTop.move('down')
}

const onMoveUp = () => {
	cards.value = cards.value.map((c) => ({
		...c,
		index: c.index === 0 ? cards.value.length - 1 : c.index - 1,
	}))

	props.state.currentIndex--
	if (props.state.currentIndex < 0)
		props.state.currentIndex = props.publications.length - 1
}

const onMoveDown = () => {
	cards.value = cards.value.map((c) => ({
		...c,
		index: c.index === cards.value.length - 1 ? 0 : c.index + 1,
	}))

	props.state.currentIndex++
	if (props.state.currentIndex >= props.publications.length)
		props.state.currentIndex = 0
}

const onMoveStart = () => (isMoving.value = true)
const onMoveEnd = () => (isMoving.value = false)

defineExpose({
	next: showNextCard,
	prev: showPrevCard,
	isMoving,
})
</script>

<template>
	<div class="featured-stack">
		<div class="cards">
			<CollectionFeaturedStackCard
				v-for="card in cards"
				ref="items"
				:key="card.id"
				:id="card.id"
				:index="card.index"
				:stack-size="cards.length"
				:publication="card"
				@move-down="onMoveDown"
				@move-up="onMoveUp"
				@move-start="onMoveStart"
				@move-end="onMoveEnd"
			/>
		</div>
	</div>
</template>

<style scoped lang="postcss">
.featured-stack {
	@apply relative w-full h-full;
}

.cards {
	@apply absolute w-[290px] h-auto aspect-[290/400] left-1/2 top-1/2 -translate-x-1/2 -translate-y-[60%];
}
</style>
